import {
  VerifyUser,
  GetStripeIntent,
  VerifySuperAffiliateUser,
  RegisterInterest,
  GetCards,
  DeleteCard,
  LockTickets,
  SubmitTransaction,
  VerifyPaystack,
  AddEnquiry,
  AppleSignedInUserUpdate,
  transactionVerify,
  JoinTheEventForPromotionAsync,
  SetPromoterCommissionOfTheEvent,
  ActiveDeactivePromoterAsync,
  ApproveRejectPromoterRequestAsync,
  PromoteTheEventAsync,
  EventsForPromotionList,
  EventListingForHost,
  StopPromotingTheEvent,
  StarPromotingTheEvent,
  PromoterOverview,
  ExistingPromotersEntryForNewEvent,
  UpdateInvoiceBankAccount,
  FollowTheHostAsync,
  GetUserNotification,
  AcknowledgeNotification,
  OnOffNotifications,
  GetFollowedHostList,
  FollowedHostListAction,
  InviteToPromoteTheEventAsync,
  GetUserNotificationFrequencyTypes,
  SaveSalesEmailNotificationPreference,
  PromoterRequestStatus,
  ExportTicketALLOCATION,
  DeleteEventProduct,
  SaveEventBasicDetails,
  addEventlocation,
  AddEventGeneral,
  AddEventDate,
  AddEventTickets,
  AddEventSummary,
  ResetPassword,
  ChangeOldPassword,
  DeleteUserEvent,
  UpdateUserProfile,
  InsertUserEvent,
  AddHost,
  AddBank,
  DeleteUserBank,
  InitiateEmailVerification,
  VerifyResetPasswordToken,
  UserRegistration,
  VerifyEmail,
  GoogleAuth,
  AppleAuth,
  GetFurpBankProviders,
  FurpGetBank,
  FurpGetBeneficiaryRequirement,
  AddThirdPartyEventUserAsync,
  GetSiteMaintenanceStatus,
  GetAvailableSeats,
  ConfirmChangeSeats,
  DeteleEventDressTerm,
  UpdateEnquiry,
  ReplyEnquiry,
  TransferTickets,
  GetTicketToTransfer,
  TicketToRecallReject
} from './manager';

import { setToken } from './instance';

// eslint-disable-next-line import/no-anonymous-default-export

export default {
  VerifyUser,
  GetStripeIntent,
  setToken,
  VerifySuperAffiliateUser,
  RegisterInterest,
  GetCards,
  DeleteCard,
  LockTickets,
  SubmitTransaction,
  VerifyPaystack,
  AddEnquiry,
  AppleSignedInUserUpdate,
  transactionVerify,
  JoinTheEventForPromotionAsync,
  SetPromoterCommissionOfTheEvent,
  ActiveDeactivePromoterAsync,
  ApproveRejectPromoterRequestAsync,
  PromoteTheEventAsync,
  EventsForPromotionList,
  EventListingForHost,
  StopPromotingTheEvent,
  StarPromotingTheEvent,
  PromoterOverview,
  ExistingPromotersEntryForNewEvent,
  UpdateInvoiceBankAccount,
  FollowTheHostAsync,
  GetUserNotification,
  AcknowledgeNotification,
  OnOffNotifications,
  GetFollowedHostList,
  FollowedHostListAction,
  InviteToPromoteTheEventAsync,
  GetUserNotificationFrequencyTypes,
  SaveSalesEmailNotificationPreference,
  PromoterRequestStatus,
  ExportTicketALLOCATION,
  DeleteEventProduct,
  SaveEventBasicDetails,
  addEventlocation,
  AddEventGeneral,
  AddEventDate,
  AddEventTickets,
  AddEventSummary,
  ResetPassword,
  ChangeOldPassword,
  DeleteUserEvent,
  UpdateUserProfile,
  InsertUserEvent,
  AddHost,
  AddBank,
  DeleteUserBank,
  InitiateEmailVerification,
  VerifyResetPasswordToken,
  UserRegistration,
  VerifyEmail,
  GoogleAuth,
  AppleAuth,
  GetFurpBankProviders,
  FurpGetBank,
  FurpGetBeneficiaryRequirement,
  AddThirdPartyEventUserAsync,
  GetSiteMaintenanceStatus,
  GetAvailableSeats,
  ConfirmChangeSeats,
  DeteleEventDressTerm,
  UpdateEnquiry,
  ReplyEnquiry,
  TransferTickets,
  GetTicketToTransfer,
  TicketToRecallReject
};
